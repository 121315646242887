import React from 'react';
import {
   Box,
   Typography,
   Card,
   MenuItem,
   FormControl,
   Select,
   TextareaAutosize,
   Dialog,
   Menu,
} from '@mui/material';
import cost from '../../../assets/cost.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import { Container } from './style';
import backImg from '../../../assets/back.svg';
import contactImg from '../../../assets/phonebook3.svg';
import styled from '@emotion/styled';
import ScheduleDate from '../../../components/message/scheduleMessage/ScheduleDate';
import MessageSummary from '../../../components/message/messageHome/MessageSummary';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
   draftmessage,
   fetchsenderIDs,
   getMsgCost,
   resetCreated,
   schedulemessage,
   selectSenderId,
   resetSelectedContacts
} from '../../../components/app/features/message/messageSlice';
import GroupContent from '../message/GroupContent';
import ContactContent from '../message/ContactContent';
import MessageContact from '../messageContact';
import Success from './Success';
import { toast } from 'react-toastify';

const Textarea = styled(TextareaAutosize)(
   ({ theme }) => `
		width: 100%;
		min-height: 150px;
      padding: 10px;
		border: 1px solid #A1A1A1;
		border-radius:5px;
		font-size:1rem;
       background: white;
       color: #49454F;
		margin-top: 2rem;
       &.placeholer{
        color: red;
       }
		&:focus {
      outline: none;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    },
     `
);
const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 ;
   box-sizing: border-box;
   white-space: nowrap;
	width: 160px;
	font-weight:700;
	border-radius:0;
	`
);

const ScheduleMessage = () => {
   const selector = useSelector(messageSelector);
   const dispatch = useDispatch();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [period, setPeriod] = React.useState(false);
   const [draftOpen, setDraftOpen] = React.useState(false);
   const [openSummary, setOpenSummary] = React.useState(false);
   const [add, setAdd] = React.useState(false);
   const [open, setOpen] = React.useState(false);
   const [date, setDate] = React.useState('');
   const [action, setAction] = React.useState('');
   const [senderId, setSenderId] = React.useState(selector?.selectedSenderId);
   const [text, setText] = React.useState('');
   const actions = ['Save to Drafts'];
   const [page, setPage] = React.useState(1);
   const [numOfContacts, setNumOfContacts] = React.useState(0);

   const closePeriod = () => setPeriod(false);

   const handleTextAreaChange = (e) => {
      const { value } = e.target;
      if (text.length > 0) {
         setPage(Math.ceil(text.length / 160));
      } else if (text === '') {
         setPage(1);
      } else {
         setPage(1);
      }
      setText(value);
   };
   const handleChange = (event) => {
      setSenderId(event.target.value);
      dispatch(selectSenderId({ senderId: event.target.value }));
   };
   const handleMenu = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   React.useEffect(() => {
      if (action === 'Save to Drafts') {
         setDraftOpen(true);
      }
   }, [action === 'Save to Drafts']);

   const getTime = (value) => {
      setDate(value);
      setPeriod(false);
   };

   React.useEffect(() => {
      if (!selector.senderIds || selector.senderIds.length < 1) {
         dispatch(fetchsenderIDs());
      }
   }, []);
   const groupIds = selector?.selectedGroups.map((group) => group.id);
   const contactIds = selector?.selectedContacts.map((contact) => contact.id);
   const numbers = selector?.selectedNumbers;

   React.useEffect(() => {
      if (selector.scheduled === true) {
         setOpen(true);
      } else {
         setOpen(false);
      }
   }, [selector.scheduled === true]);

   const schedule = () => {
      dispatch(
         schedulemessage({
            content: text,
            senderId: senderId,
            scheduledAt: date,
            contactids: contactIds,
            groupids: groupIds,
            numbers,
         })
      );
      setText('');
      setDate('');
   };

   const currentDate = new Date().toISOString();
   const draft = () => {
      if (text !== '') {
         dispatch(
            draftmessage({
               content: text,
               senderId: senderId,
               scheduledAt: currentDate,
               contactids: contactIds,
               groupids: groupIds,
               numbers,
            })
         );
         window.history.back();
      } else {
         toast.info('type a message');
      }
   };

   const setClose = () => setAnchorEl(false);

   React.useEffect(() => {
      dispatch(
         getMsgCost({
            content: '',
            senderId: senderId,
            scheduledAt: date,
            contactids: contactIds,
            groupids: groupIds,
            numbers,
         })
      );
   }, [selector?.selectedContacts || selector?.selectedGroups]);

   React.useEffect(() => {
      if (selector.msgCost?.dNDCount || selector.msgCost?.nonDNDCount) {
         setNumOfContacts(
            selector.msgCost.dNDCount + selector.msgCost.nonDNDCount
         );
      } else {
         setNumOfContacts(0);
      }
   }, [selector.msgCost]);

   const resetMsgSummary = () => {
      setOpenSummary(false);
   };

   const maxCharactersPerPage = 160;
   const calculatePageCount = (text) => {
      return Math.ceil(text.length / maxCharactersPerPage);
   };
   const pageCount = calculatePageCount(text);
   const remainingCharacters =
      maxCharactersPerPage - (text.length % maxCharactersPerPage);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => {
                     if (text !== '') {
                        setDraftOpen(true);
                     } else {
                        window.history.back();
                     }
                  }}
               />
               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleMenu(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>
               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '3rem',
                  }}
               >
                  Schedule Message
               </Typography>

               <Typography
                  sx={{
                     margin: '1.5rem 0 0.5rem 0',
                     fontWeight: 600,
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     color: '#0F0E0E',
                  }}
               >
                  Sender ID
               </Typography>
               <FormControl fullWidth>
                  <Select
                     id="demo-simple-select"
                     value={senderId}
                     onChange={handleChange}
                     sx={{
                        backgroundColor: '#fff',
                        fontWeight: 600,
                        padding: '25px 0 !important',

                        '& .MuiOutlinedInput-notchedOutline': {
                           borderColor: '#A1A1A1', // change this to the desired color
                        },
                     }}
                  >
                     {selector?.senderIds ? (
                        selector?.senderIds
                           .filter((x) => x.status == 'approved')
                           .map((senderId, index) => (
                              <MenuItem value={senderId?.senderId} key={index}>
                                 {senderId?.senderId}
                              </MenuItem>
                           ))
                     ) : (
                        <MenuItem value={1}>No Sender Ids</MenuItem>
                     )}
                  </Select>
               </FormControl>
               <Box
   sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1.5rem',
      marginBottom:'0.5rem'
   }}
>
   <Typography
      sx={{
         fontWeight: 600,
         fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
         color: '#0F0E0E',
      }}
   >
      Recipients
   </Typography>
   <Typography
      sx={{
         fontWeight: 400,
         color: '#374da0',
         cursor: 'pointer',
      }}
      onClick={() => {
         dispatch(resetSelectedContacts());
      }}
   >
      Clear
   </Typography>
</Box>

               <Box sx={{ position: 'relative' }}>
                  <img
                     src={contactImg}
                     alt="icon"
                     style={{
                        width: '30px',
                        position: 'absolute',
                        zIndex: 10,
                        right: '10px',
                        bottom: '50%',
                        transform: 'translateY(50%)',
                        cursor: 'pointer',
                     }}
                     onClick={() => setAdd(true)}
                  />

                  <Box
                     sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                        bgcolor: 'background.paper',
                        maxHeight: 100,
                        border: '1px solid #A1A1A1',
                        minHeight: '60px',
                        background: '#fff',
                        borderRadius: '5px',
                        padding: '10px',
                        paddingRight: '40px',
                        overflow: 'auto',
                        width: '100%',
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'grey',
                        // '&::-webkit-scrollbar': {
                        //    width: 0,
                        //    height: 0,
                        // },
                     }}
                  >
                     {selector?.selectedGroups.length > 0
                        ? selector?.selectedGroups.map((item, id) => (
                             <GroupContent key={id} item={item} />
                          ))
                        : null}
                     {selector.selectedContacts.length > 0
                        ? selector.selectedContacts.map((item, id) => (
                             <ContactContent key={id} item={item} />
                          ))
                        : null}

                     {selector.selectedNumbers.length > 0
                        ? selector.selectedNumbers.map((item, id) => (
                             <ContactContent key={id} item={{ number: item }} />
                          ))
                        : null}
                  </Box>
               </Box>
               <Typography
                  sx={{
                     margin: '1rem 0',
                     display: 'flex',
                     fontWeight: 600,
                     color: '#374da0',
                     cursor: 'pointer',
                     width: 'max-content',
                  }}
                  onClick={() => {
                     dispatch(
                        getMsgCost({
                           content: text,
                           senderId: senderId,
                           scheduledAt: date,
                           contactids: contactIds,
                           groupids: groupIds,
                           numbers,
                        })
                     );
                     setOpenSummary(true);
                  }}
               >
                  <img src={cost} alt="icon" style={{ marginRight: '10px' }} />
                  view pricing info
               </Typography>
               
               <Textarea
                  margin="normal"
                  name="desc"
                  aria-label="about"
                  minRows={1}
                  // placeholder='Enter message here...'
                  onChange={handleTextAreaChange}
                  value={text}
                  variant="outlined"
                  id="group_desc"
               />
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     marginBottom: '2rem',
                  }}
               >
                  <Typography
                     sx={{
                        width: 'max-content',

                        color: '#374DAO',
                        fontWeight: '600',
                     }}
                  >
                     {remainingCharacters}/{pageCount}
                  </Typography>
                  <Typography
                     sx={{
                        width: 'max-content',

                        color: '#374DAO',
                        fontWeight: '600',
                     }}
                  >
                     {`	contacts: `}
                     {numOfContacts}
                  </Typography>
               </Box>
               <Box
                  sx={{
                     // border: '1px solid red',
                     width: '300px',
                     margin: '1.5rem auto',
                     borderRadius: '8px',
                     backgroundColor: '#374DA0',
                     color: '#fff',
                     display: 'flex',
                     justifyContent: 'space-between',
                  }}
               >
                  <Typography
                     sx={{
                        textAlign: 'center',
                        width: '100%',
                        padding: '10px',
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        if (date === '') {
                           setPeriod(true);
                        } else if (text === '') {
                           toast.info('type a message');
                        } else if (
                           contactIds.length === 0 &&
                           groupIds.length === 0 &&
                           numbers.lenth === 0
                        ) {
                           toast.info('select contacts');
                        } else if (date !== '' && text !== '') {
                           schedule();
                        }
                     }}
                  >
                     Schedule Message
                  </Typography>
               </Box>
               <Dialog onClose={() => setPeriod(false)} open={period}>
                  <ScheduleDate getTime={getTime} closePeriod={closePeriod} />
               </Dialog>
               <Dialog onClose={() => setAdd(false)} open={add}>
                  <MessageContact
                     contactText={'/send-message/select-contact'}
                     groupsText={'/send-message/select-group'}
                     pasteText={'/send-message/select-paste'}
                  />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setAction('');
                     setDraftOpen(false);
                  }}
                  open={draftOpen}
               >
                  <Card sx={{ padding: '20px' }}>
                     <Typography
                        sx={{
                           textAlign: 'center',
                           marginBottom: '2rem',

                           fontWeight: '600',
                           fontSize: '1rem',
                        }}
                     >
                        You have not completed scheduling this message. How do
                        you want to proceed?
                     </Typography>
                     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                           sx={{
                              color: '#374DAO',
                              fontWeight: '600',
                              marginRight: '1rem',
                              cursor: 'pointer',
                              fontSize: '1rem',
                           }}
                           onClick={() => draft()}
                        >
                           Save as Draft
                        </Typography>
                        <Typography
                           sx={{
                              color: '#374DAO',
                              fontWeight: '600',
                              cursor: 'pointer',
                              fontSize: '1rem',
                           }}
                           onClick={() => window.history.back()}
                        >
                           Discard
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <Dialog onClose={() => setOpenSummary(false)} open={openSummary}>
                  <MessageSummary resetMsgSummary={resetMsgSummary} />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setOpen(false);
                     dispatch(resetCreated());
                  }}
                  open={open}
               >
                  <Success />
               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default ScheduleMessage;
