import React from 'react';
import {
   Box,
   Typography,
   Card,
   MenuItem,
   Menu,
   Button,
   Dialog,
   Tooltip,
   IconButton,
} from '@mui/material';
import styled from '@emotion/styled';
import { Container } from './style';
import backImg from '../../../assets/back.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import groupIcon from '../../../assets/group.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import {
   archiveMessages,
   deleteMessage,
   getSingleMessage,
   getUserMessages,
   // sendExistingMessage,
} from '../../../components/app/features/message/messageSlice';
import StatusMeaning from '../../../components/modal/StatusMeaning';
// import dayjs from 'dayjs';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	// padding:0;
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:600;	
	border-radius:0;
	`
);
// const FlatButton = styled(Button)(
//    ({ theme }) => `
// 	background-color:#fff;
//  font-size:0.9rem;
// 	font-weight:500;
// 	font-family: 'Roboto', sans-serif;
// 	border-radius:0;
//  width: 45%;
//  border-radius: 8px;
// 	`
// );

const HistoryDetails = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const selector = useSelector(messageSelector);
   const [isDelete, setIsDelete] = React.useState(false);
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [isArchive, setIsArchive] = React.useState(false);
   const setClose = () => setAnchorEl(false);
   const [value, setValue] = React.useState('');
   const [isCopied, setIsCopied] = React.useState(false);

   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const handleCopyClick = () => {
      navigator.clipboard.writeText(value).then(() => {
         setIsCopied(true);
         setTimeout(() => {
            setIsCopied(false);
         }, 2000);
      });
   };

   // const resendMsg = () => {
   //    const newDate = dayjs(new Date());
   //    const currentDate = newDate.toISOString();
   //    if (selector.message) {
   //       const contactIds = [];
   //       const groupIds = [];
   //       if (selector.message.contacts) {
   //          selector.message.contacts.map((item) => contactIds.push(item.id));
   //       }
   //       if (selector.message.groups) {
   //          selector.message.groups.map((item) => groupIds.push(item.id));
   //       }
   //       dispatch(
   //          sendExistingMessage({
   //             id: params.id,
   //             msg: {
   //                content: selector.message.content,
   //                senderId: selector.message.senderId,
   //                scheduledAt: currentDate,
   //                contactids: contactIds,
   //                groupids: groupIds,
   //             },
   //          })
   //       );
   //    }
   // };

   const actions = ['Archive', 'Resend Message', 'Delete'];
   const params = useParams();

   const handleValueChange = (event) => {
      setValue(event.target.value);
   };

   React.useEffect(() => {
      dispatch(getSingleMessage({ id: params.id }));
   }, [params.id]);

   React.useEffect(() => {
      if (selector?.message.content) {
         setValue(selector?.message?.content);
      }
   }, [selector?.message?.content]);

   React.useEffect(() => {
      if (action === 'Delete') {
         setIsDelete(true);
      } else if (action === 'Archive') {
         setIsArchive(true);
      } else if (action === 'Resend Message') {
         navigate(`/send-message/drafts-edit/${params.id}`);
      }
   }, [action]);

   const date = new Date(selector?.message.createdAt);
   const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
   });

   const groupCount = selector?.message?.groups
      ? Number(selector?.message.groups.map((item) => item.contacts.length))
      : 0;
   const contactCount = selector.message?.contacts
      ? groupCount + selector.message?.contacts.length
      : 0;

   const deleteItem = () => {
      dispatch(
         deleteMessage({
            ids: String(selector.message.id && selector.message.id),
         })
      );
      dispatch(getUserMessages());
      setAction('');
      setIsDelete(false);
      window.history.back();
   };
   const archiveMsg = () => {
      dispatch(archiveMessages([selector.message.id && selector.message.id]));
      dispatch(getUserMessages());
      setAction('');
      setIsArchive(false);
      window.history.back();
   };

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <Dialog
                  onClose={() => {
                     setIsDelete(false);
                     setAction('');
                  }}
                  open={isDelete}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Are you sure you want to delete the selected messages?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsDelete(false);
                              setAction('');
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => deleteItem()}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <Dialog
                  onClose={() => {
                     setIsArchive(false);
                     setAction('');
                  }}
                  open={isArchive}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Archive selected messages?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsArchive(false);
                              setAction('');
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => archiveMsg()}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleChange(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>

               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '1rem',
                  }}
               >
                  <img
                     src={groupIcon}
                     style={{ marginRight: '10px' }}
                     alt="icon_group"
                  />
                  {selector.message.senderId}
               </Typography>
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: '0.9rem',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  }}
               >
                  {`${contactCount} contacts`}
               </Typography>
               <Typography
                  sx={{
                     fontWeight: 600,
                     fontSize: '0.9rem',
                     color: '#374DA0',
                     marginTop: '2rem',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     position: 'relative',
                  }}
               >
                  {formattedDate}
                  <StatusMeaning />
                  <Tooltip title="Copy Message Content">
                     <IconButton onClick={() => handleCopyClick()}>
                        <ContentCopyIcon />
                     </IconButton>
                  </Tooltip>
                  {isCopied && (
                     <Typography
                        sx={{
                           position: 'absolute',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           right: 0,
                           bottom: '-10px',
                        }}
                     >
                        copied!
                     </Typography>
                  )}
               </Typography>

               <textarea
                  value={value}
                  onChange={handleValueChange}
                  style={{
                     outline: 'none',
                     marginTop: '1.5rem',
                     width: '100%',
                     minHeight: '180px',
                     lineHeight: 1.4,
                     borderColor: '#BCC4DF',
                     borderRadius: '8px',
                     padding: '15px',
                     fontWeight: 500,
                     fontSize: '1rem',
                     color: '#0F0E0E',
                     background: '#fff',
                  }}
                  disabled
               />

               {/* recipient delivery report */}

               {/* <Box
                  sx={{
                     margin: '1.5rem 0',
                     display: 'flex',
                     justifyContent: 'space-between',
                  }}
               >
                  <FlatButton
                     sx={{ color: '#0FA958', border: '1px solid #0FA958' }}
                  >
                     Sent: 40
                  </FlatButton>
                  <FlatButton
                     sx={{ border: '1px solid #FF1818', color: '#FF1818' }}
                  >
                     Failed: 0
                  </FlatButton>
               </Box> */}
               {/* <Typography
                  sx={{
                     fontWeight: 700,
                     fontSize: '0.9rem',
                     color: '#374DA0',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     marginBottom: '1.5rem',
                  }}
               >
                  Recipient delivery report
               </Typography> */}
               <Box sx={{ marginTop: '1rem' }}>
                  {selector?.message?.contacts
                     ? selector?.message?.contacts.map((item, indx) => (
                          <Card
                             sx={{
                                display: 'flex',
                                padding: '10px 15px',
                                marginBottom: '10px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                             }}
                             key={indx}
                          >
                             <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                             >
                                <Box
                                   sx={{
                                      width: '40px',
                                      height: '40px',
                                      borderRadius: '50%',
                                      backgroundColor: ' #D7DBEC',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                   }}
                                >
                                   <Typography
                                      sx={{
                                         fontWeight: '600',
                                         fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                                      }}
                                   >
                                      {item?.fullName
                                         ?.split('')[0]
                                         ?.toUpperCase()}
                                   </Typography>
                                </Box>
                                <Box sx={{ paddingLeft: '15px' }}>
                                   <Typography
                                      sx={{
                                         fontWeight: '600',
                                         fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                      }}
                                   >
                                      {item.fullName}
                                   </Typography>
                                   <Typography
                                      sx={{
                                         fontWeight: '600',
                                         fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                      }}
                                   >
                                      {item.number}
                                   </Typography>
                                </Box>
                             </Box>
                             {item.deliveryStatus != null && (
                                <Typography
                                   sx={{
                                      fontWeight: '600',
                                      fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                      color:
                                         item.deliveryStatus === 'Delivered'
                                            ? '#0FA958'
                                            : item.deliveryStatus === 'Failed'
                                            ? '#d6040f'
                                            : '#4032a8',
                                   }}
                                >
                                   {item.deliveryStatus}
                                </Typography>
                             )}
                          </Card>
                       ))
                     : null}
                  {contactCount === 0 && (
                     <Typography
                        sx={{
                           color: '#374da0',
                           fontWeight: '600',
                           textAlign: 'center',
                           fontSize: '1rem',
                        }}
                     >
                        No Added Contacts
                     </Typography>

                       
                  )}
                   <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100vh" // Adjust as needed
    >

    </Box>
                  {selector?.message?.groups &&
                     selector?.message?.groups.map((group) =>
                        group.contacts.map((contact, indx) => (
                           <Card
                              sx={{
                                 display: 'flex',
                                 padding: '10px 15px',
                                 marginBottom: '10px',
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                              }}
                              key={indx}
                           >
                              <Box
                                 sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                 <Box
                                    sx={{
                                       width: '40px',
                                       height: '40px',
                                       borderRadius: '50%',
                                       backgroundColor: ' #D7DBEC',
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          fontWeight: '600',
                                          fontSize:
                                             'clamp(1rem, 1.5vw, 1.1rem)',
                                       }}
                                    >
                                       {contact.fullName
                                          ?.split('')[0]
                                          ?.toUpperCase()}
                                    </Typography>
                                 </Box>
                                 <Box sx={{ paddingLeft: '15px' }}>
                                    <Typography
                                       sx={{
                                          fontWeight: '600',
                                          fontSize:
                                             'clamp(0.9rem, 1.5vw, 1rem)',
                                       }}
                                    >
                                       {contact.fullName}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontWeight: '600',
                                          fontSize:
                                             'clamp(0.9rem, 1.5vw, 1rem)',
                                       }}
                                    >
                                       {contact.number}
                                    </Typography>
                                 </Box>
                              </Box>
                              {contact.deliveryStatus != null && (
                                 <Typography
                                    sx={{
                                       fontWeight: '600',
                                       fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                       color:
                                          contact.deliveryStatus === 'Delivered'
                                             ? '#0FA958'
                                             : contact.deliveryStatus ===
                                               'Failed'
                                             ? '#d6040f'
                                             : '#4032a8',
                                    }}
                                 >
                                    {contact.deliveryStatus}
                                 </Typography>
                              )}
                           </Card>
                        ))
                     )}
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default HistoryDetails;
