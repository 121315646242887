import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Divider } from '@mui/material';
import './modal.css';

const StatusMeaning = () => {
  const [open, setOpen] = useState(false); 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Button to open the modal */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleOpen} 
        sx={{
          backgroundColor: '#374DA0', 
          textTransform: 'none', 
          fontWeight: 'bold', 
          padding: '12px 24px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#0056b3',
          }
        }}
      >
        View status meanings
      </Button>

      {/* Modal */}
      <Modal
        open={open}
        onClose={(e) => e.stopPropagation()} 
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          className="modal-box" 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '70%', md: '50%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          {/* Modal header */}
          <Box sx={{ bgcolor: '#f5f5f5', padding: '20px' }}> {/* Slightly smaller padding */}
            <Typography 
              id="modal-title" 
              variant="h5" 
              component="h2" 
              sx={{ fontWeight: 'bold', color: '#333', fontSize: '20px' }} 
            >
             Below is an explanation of our message statuses and their meanings:

            </Typography>
          </Box>

          <Divider />

          {/* Scrollable content area */}
          <Box 
            className="modal-content" 
            sx={{
              padding: '20px',
              maxHeight: '65vh', 
              overflowY: 'auto', 
            }}
          >
            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              1. Delivered: 
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
            The message has been successfully delivered to the handset, and this status has been confirmed by the telco operators.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              2. Sent
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
            The message has been sent to the telco operators, but they have not yet provided feedback on whether the handset received it. This could be due to the phone being switched off, out of coverage, or the telco operator has not yet updated the status.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              3. Rejected:
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
            The number is either active on DND or is barred from receiving messages.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              4.   Failed: 
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
          The number is switched off or otherwise unavailable to receive messages.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              5. Invalid Number: 
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
            The number is not valid or does not exist on the network.
            </Typography>

            <Typography variant="h6" sx={{ color: '#007bff', fontWeight: 'bold', fontSize: '18px' }}>
              6.  Gateway Error: 
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3, fontSize: '14px' }}>
           There was an error on the part of the network operator.
            </Typography>

          
          </Box>

          <Divider />

          {/* Okay button */}
          <Box sx={{ textAlign: 'center', padding: '20px' }}> 
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleClose}
              sx={{
                textTransform: 'none', 
                fontWeight: 'bold', 
                padding: '12px 24px', 
                fontSize: '16px',  
                backgroundColor: '#007bff',
                '&:hover': {
                  backgroundColor: '#0056b3',
                }
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StatusMeaning;
