import React from 'react';
import { Box, Typography, Card, Button, Checkbox, Dialog } from '@mui/material';
import * as Yup from 'yup';
import backImg from '../../../assets/back.svg';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import Success from './Success';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { createContact } from '../../../components/app/features/contacts/contactSlice';
import { useSearchParams } from 'react-router-dom';

const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #EFEEF4;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 50%;
text-align: center;
padding:10px 0;
font-size: 1rem;
border-radius:5px;
margin: 1rem auto;

&:hover {background: #374DA0;}
@media(max-width:615px) {
   width:80%;
}
`
);

const TextInput = styled.textarea(
   ({ theme }) => `
       background: white;
       color: #49454F;
       width:100%;
       height:20vh;
       padding:5px
     `
);

const CopyPaste = () => {
   const [isOpen, setIsOpen] = React.useState(false);
   const [contacts, setContacts] = React.useState([]);
   const [checkedList, setCheckedList] = React.useState([]);
   const dispatch = useDispatch();
   const [searchParams] = useSearchParams();
   const selector = useSelector(contactSelector);
   React.useEffect(() => {
      if (selector.created === true) {
         setIsOpen(true);
      }
   }, [selector.created]);

   const isLoading = useSelector((state) => state.contact.loading);

   const handleCheckboxClick = (id) => {
      setCheckedList((prev) =>
         prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      );
   };

   React.useEffect(() => {
      const validIds = contacts
         .filter((item) => {
            let cleanedNumber = String(item.number).replace(/[^0-9]/g, '').trim();
            if (cleanedNumber.startsWith('234') && cleanedNumber.length === 13) {
               cleanedNumber = '0' + cleanedNumber.slice(3);
            }
            if (/^(70|80|81|90|91)/.test(cleanedNumber) && cleanedNumber.length === 10) {
               cleanedNumber = '0' + cleanedNumber;
            }
            return /^(070|080|081|090|091)\d{8}$/.test(cleanedNumber);
         })
         .map((item) => item.id);

      setCheckedList(validIds);
   }, [contacts]);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     margin: '2rem auto',
                     marginBottom: '3rem',
                  }}
               >
                  Create Contacts
               </Typography>

               <Formik
                  initialValues={{
                     numbers: '',
                     userId: selector?.admin?.id,
                  }}
                  validationSchema={Yup.object().shape({
                     numbers: Yup.string().required(
                        'At least one Phone number is required'
                     ),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                     const validContacts = contacts.filter((item) =>
                        checkedList.includes(item.id)
                     );

                     if (validContacts.length > 0) {
                        dispatch(
                           createContact({
                              userId: selector?.admin?.id,
                              contact: validContacts.map((x) => ({
                                 fullName: 'unknown',
                                 number: x.number,
                              })),
                              groupId: searchParams.get('groupId'),
                           })
                        );
                     } else {
                        console.error('No valid contacts to send.');
                     }
                  }}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form noValidate onSubmit={handleSubmit}>
                        <label
                           htmlFor="numbers"
                           style={{ marginBottom: '5px', display: 'block' }}
                        >
                           Contacts
                        </label>
                        <TextInput
                           name="numbers"
                           id="numbers"
                           placeholder="Paste numbers here"
                           onBlur={handleBlur}
                           onChange={(e) => {
                              const numbers = e.target.value;
                              setFieldValue('numbers', numbers);

                              if (numbers) {
                                 const re = /(?:[-+() ]*\d){10,13}/gm;
                                 const match = numbers.match(re);
                                 if (match) {
                                    const res = match.map((s, i) => {
                                       return {
                                          id: i,
                                          fullName: '',
                                          number: s.trim(),
                                       };
                                    });
                                    setContacts(res);
                                 } else {
                                    setContacts([]);
                                 }
                              } else {
                                 setContacts([]);
                              }
                           }}
                           value={values.numbers}
                        />
                        <Box
                           sx={{
                              marginTop: '2rem',
                              height: '35vh',
                              overflowY: 'auto',
                           }}
                        >
                           {contacts != null &&
                              contacts?.map((item) => {
                                 let cleanedNumber = String(item.number).replace(
                                    /[^0-9]/g,
                                    ''
                                 ).trim();
                                 let isValid = false;

                                 if (cleanedNumber.startsWith('234') && cleanedNumber.length === 13) {
                                    cleanedNumber = '0' + cleanedNumber.slice(3);
                                 }
                                 if (
                                    /^(70|80|81|90|91)/.test(cleanedNumber) &&
                                    cleanedNumber.length === 10
                                 ) {
                                    cleanedNumber = '0' + cleanedNumber;
                                 }
                                 isValid = /^(070|080|081|090|091)\d{8}$/.test(cleanedNumber);

                                 return (
                                    <Card
                                       sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          padding: '10px 15px',
                                          marginBottom: '10px',
                                          cursor: 'pointer',
                                          '&:hover': {
                                             boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.3)',
                                          },
                                       }}
                                       key={item?.id}
                                    >
                                     
                                       <Box sx={{ paddingLeft: '15px' }}>
                                          <Typography
                                             sx={{
                                                fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                                fontWeight: 600,
                                             }}
                                          >
                                             {item.fullName}
                                          </Typography>
                                          <Typography
                                             sx={{
                                                fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                                fontWeight: 600,
                                             }}
                                          >
                                               <Checkbox
                                          checked={checkedList.includes(item.id)}
                                          onChange={() => handleCheckboxClick(item.id)}
                                       />
                                             {item.number}
                                          </Typography>
                                       </Box>
                                       <Typography
                                          sx={{
                                             color: isValid ? 'green' : 'red',
                                             alignSelf: 'center',
                                             fontWeight: 600,
                                             padding: '2px 10px',
                                             border: `2px solid ${isValid ? 'green' : 'red'}`,
                                             background: `${isValid ? '#34C75933' : '#FF3B3033'}`,
                                             borderRadius: '15px',
                                             fontWeight: 'bold',
                                          }}
                                       >
                                          {isValid ? 'Valid' : 'Invalid'}
                                          
                                       </Typography>
                             
                                    </Card>
                                 );
                              })}
                        </Box>

                        <Box display={'flex'} sx={{ marginTop: '1rem' }}>
                           <FlatButton
                              disabled={
                                 !values.numbers ||
                                 !!(errors.numbers && touched.numbers && errors.numbers) ||
                                 isLoading === 'pending'
                              }
                              type="submit"
                              onClick={handleSubmit}
                           >
                              {isLoading === 'pending' ? 'Adding...' : 'Add contacts'}
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
               <Dialog
                  onClose={() => {
                     setIsOpen(false);
                  }}
                  open={isOpen}
               >
                  <Success count={checkedList.length} />
               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default CopyPaste;



